<template>
  <el-card class="material mt-20">
    <div slot="header">
      <div class="dp-flex">
        <div>系列预算</div>
        <div class="ml-10"><el-switch v-model="campaign.budget_optimize_switch"></el-switch></div>
      </div>
      <div class="tips mt-10">开启后，预算由系列根据广告组的实时表现分配给广告组，以获得更多转化</div>
    </div>

    <div>
      <el-form
        :model="campaign"
        :rules="rules"
        label-position="left"
        label-width="100px"
        ref="form"
      >
        <!-- 预算 -->
        <div>
          <div
            v-show="!campaign.budget_optimize_switch"
            class="mb-10"
          >
            预算
          </div>
          <el-form-item
            label="每日"
            prop="budget"
          >
            <el-input
              v-model="campaign.budget"
              placeholder=""
            >
              <div slot="append">USD</div>
            </el-input>
          </el-form-item>
        </div>

        <!-- 投放策略 -->
        <div
          v-show="!campaign.budget_optimize_switch"
          class="dp-flex"
        >
          <div>
            <el-form-item
              label="投放策略"
              prop="bid_strategy"
            >
              <el-select
                v-model="campaign.bid_strategy"
                placeholder=""
                class="w-100"
              >
                <el-option
                  v-for="bid_strategy in adsConsts.bid_strategy"
                  :label="bid_strategy.desc"
                  :value="bid_strategy.key"
                  :key="bid_strategy.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div
            class="w-100"
            v-show="campaign.bid_strategy != 'LOWEST_COST_WITHOUT_CAP'"
          >
            <el-form-item prop="bid_amount">
              <el-input
                v-model="campaign.bid_amount"
                placeholder=""
              >
                <div slot="append">US$/转化</div>
              </el-input>
            </el-form-item>
          </div>
        </div>
        <!-- 赞助方 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="赞助方">
              <el-input
                v-model="campaign.dsa_payor"
                placeholder=""
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收益方">
              <el-input
                v-model="campaign.dsa_beneficiary"
                placeholder=""
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 排期 -->
        <div class="mt-20">
          <div class="dp-flex mb-10">
            <div>排期</div>
            <div class="ml-10">
              <el-checkbox
                label="持续投放"
                v-model="campaign.schedule_type"
                true-label="SCHEDULE_FROM_NOW"
                false-label="SCHEDULE_START_END"
              ></el-checkbox>
            </div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="开始时间"
                prop="schedule_start_time"
              >
                <el-date-picker
                  :picker-options="pickerOptions"
                  v-model="campaign.schedule_start_time"
                  type="datetime"
                  placeholder="选择开始时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-show="campaign.schedule_type == 'SCHEDULE_START_END'"
            >
              <el-form-item
                label="结束时间"
                prop="schedule_end_time"
              >
                <el-date-picker
                  :picker-options="pickerOptions"
                  v-model="campaign.schedule_end_time"
                  type="datetime"
                  placeholder="选择结束时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    campaign: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const bid_strategyValidator = (rule, value, callback) => {
      if (!this.campaign.budget_optimize_switch && !value) {
        callback(new Error('请选择投放策略'));
      }
      callback();
    };
    const bid_amountValidator = (rule, value, callback) => {
      if (!this.campaign.budget_optimize_switch && this.campaign.bid_strategy != 'LOWEST_COST_WITHOUT_CAP' && !value) {
        callback(new Error('请填写投放金额'));
      }
      callback();
    };
    const schedule_end_timeValidator = (rule, value, callback) => {
      console.log('!this.schedule_type, !value', !this.campaign.schedule_type, !value);
      if (!this.campaign.schedule_type && !value) {
        callback(new Error('请选择投放结束时间'));
      }
      callback();
    };
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000; // 选当前时间之后的时间            return time.getTime() > Date.now() // 选当前时间之前的时间          }
        },
      },
      rules: {
        budget: [{ required: true, message: '请输入日预算', trigger: 'blur' }],
        bid_strategy: [
          {
            validator: bid_strategyValidator,
            trigger: 'blur',
          },
        ],
        bid_amount: [
          {
            validator: bid_amountValidator,
            trigger: 'blur',
          },
        ],
        schedule_start_time: [{ required: true, message: '请选择投放开始时间', trigger: 'blur' }],
        schedule_end_time: [{ validator: schedule_end_timeValidator, trigger: 'blur' }],
      },
    };
  },
  methods: {
    // 验证表单
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          console.log('series验证', valid);
          if (valid) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
  },
  computed: {
    ...mapGetters('base', {
      adsConsts: 'adsConsts',
    }),
  },
  watch: {
    // 持续投放修改
    'campaign.schedule_type': function (val) {
      if (val) {
        this.$emit('changeCampaign', 'schedule_end_time', null);
      }
    },
    // 系列预算优化
    'campaign.budget_optimize_switch': function (val) {
      this.$emit('changeCampaign', 'bid_strategy', 'LOWEST_COST_WITHOUT_CAP');
      this.$emit('changeCampaign', 'bid_amount', null);
    },
  },
};
</script>
<style lang="scss" scope>
.material {
  .tips {
    color: #999;
    font-size: 12px;
  }
}
</style>
