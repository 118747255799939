<template>
  <div class="slogan">
    <div
      v-for="(slogan, index) in slogans"
      :key="index"
      class="mb-20 bg-fb sloganItem"
      :class="{
        selected: selectedIndex.includes(index),
      }"
      @click="changeSelectedIndex(index)"
      v-loading="loadingList[index]"
      element-loading-background="rgba(0, 0, 0, 0.6)"
    >
      <el-form
        label-width="60px"
        :model="slogan"
        :rules="rules"
        ref="form"
      >
        <el-form-item
          prop="showConent"
          label-width="0"
        >
          <el-form-item
            label="正文"
            prop="message"
          >
            <el-tooltip
              placement="bottom"
              :disabled="edit || !slogan.content"
            >
              <pre slot="content">{{ slogan.content }}</pre>
              <el-input
                type="textarea"
                placeholder="请输入译文"
                :autosize="{ minRows: 4 }"
                v-model="slogan[slogan.showConent ? 'content' : 'message']"
                class="bg-fb"
                :disabled="!edit"
                @change="$emit('changeSlogan')"
              ></el-input>
            </el-tooltip>
          </el-form-item>
          <div
            class="opear dp-flex justify-end"
            v-show="edit"
          >
            <el-button
              type="text"
              @click="chatGtpTranslate(index)"
              v-show="slogan.showConent"
              >翻译</el-button
            >
            <el-button
              type="text"
              @click="lookContent(index)"
              >{{ slogan.showConent ? '返回译文' : '切换中文' }}</el-button
            >
            <el-button
              type="text"
              @click="chatGPTToggle(index, 4)"
              :disabled="!slogan.message"
              >更生动</el-button
            >
            <el-button
              type="text"
              @click="chatGPTToggle(index, 5)"
              :disabled="!slogan.message"
              >更精简</el-button
            >

            <el-button
              type="text"
              @click="$emit('deleteSlogan', index)"
              v-show="slogans.length > 1"
              >删除</el-button
            >
          </div>
        </el-form-item>
        <el-form-item
          label="标题"
          v-show="edit"
        >
          <el-input
            placeholder="请输入内容"
            v-model="slogan.title"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="描述"
          v-show="edit"
        >
          <el-input
            placeholder="请输入内容"
            v-model="slogan.description"
          ></el-input>
        </el-form-item>
      </el-form>

      <!-- <div class="showTranslation mt-20 pt-10">
        <pre>{{ slogan.message }}</pre>
      </div> -->
      <div
        class="seletedIcon"
        v-show="selectedIndex.includes(index)"
      >
        <i class="el-icon-check icon"></i>
      </div>
    </div>
    <el-button
      class="w-100"
      v-show="!edit"
      @click="addSelectSlogan"
      >加入广告语</el-button
    >
  </div>
</template>
<script>
export default {
  props: {
    slogans: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: false,
    },
    chatGtpSloganOptimization: {
      type: Function,
      default: () => Promise.resolve(),
    },
    chatGtpSloganTranslate: {
      type: Function,
      default: () => Promise.resolve(),
    },
  },
  data() {
    const validateMessage = (rule, value, callback) => {
      if (value) {
        callback(new Error('请切换回正文，确认译文内容'));
      }
      callback();
    };
    return {
      selectedIndex: [],
      loadingList: [],
      rules: {
        message: [{ required: true, message: '请输入广告语', trigger: ['blur', 'change'] }],
        showConent: [
          {
            validator: validateMessage,
          },
        ],
      },
    };
  },
  methods: {
    // 选择广告语
    changeSelectedIndex(index) {
      if (this.edit) return;
      const i = this.selectedIndex.indexOf(index);
      if (i == -1) {
        this.selectedIndex.push(index);
      } else {
        this.selectedIndex.splice(i, 1);
      }
    },
    // 验证
    validate() {
      let promiseList = [];
      console.log(this.slogans);
      this.$refs.form.forEach((item, index) => {
        const validateItem = new Promise((resolve, reject) => {
          console.log('验证message');
          this.$refs.form[index].validate((valid) => {
            console.log('验证message', valid);

            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
        promiseList.push(validateItem);
      });

      return Promise.all(promiseList);
    },
    // 加入广告语
    addSelectSlogan() {
      if (this.selectedIndex.length == 0) {
        this.$message.error('请选择广告语');
        return;
      }
      this.$emit('addSelectSlogan', this.selectedIndex);
    },
    //  更生动、更精简
    chatGPTToggle(index, type) {
      this.$set(this.loadingList, index, true);
      this.chatGtpSloganOptimization(index, type).finally(() => {
        this.$set(this.loadingList, index, false);
      });
    },
    // 翻译
    chatGtpTranslate(index) {
      this.$set(this.loadingList, index, true);
      this.chatGtpSloganTranslate(index).finally(() => {
        this.$set(this.loadingList, index, false);
        this.$set(this.slogans[index], 'showConent', false);
        this.$refs.form[index].clearValidate('showConent');
      });
    },
    // 切换显示中译文
    lookContent(index) {
      this.$set(this.slogans[index], 'showConent', !this.slogans[index].showConent);
      this.$refs.form[index].clearValidate('showConent');
    },
  },
  watch: {
    edit(val) {
      if (!val) {
        this.selectedIndex = [0];
      } else {
        this.selectedIndex = [];
      }
    },
  },
};
</script>
<style scoped lang="scss">
.slogan {
  .bg-fb {
    background: #fbfbfb;
  }
  .sloganItem {
    border-radius: 8px;
    padding: 18px;
    position: relative;

    &.selected {
      border: 1px solid #368bed;
      background: #fff;
      position: relative;
      ::v-deep .el-textarea__inner,
      ::v-deep .el-input__inner {
        background: #fff;
      }
    }
    ::v-deep .el-textarea__inner,
    ::v-deep .el-input__inner {
      background: #fefefe;
    }
    .seletedIcon {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 20px;
      color: #fff;
      &::before {
        content: '';
        width: 0;
        height: 0;
        display: block;
        border: 20px solid transparent;
        border-bottom: 20px solid #368bed;
        border-right: 20px solid #368bed;
        position: absolute;
        bottom: 0;
        right: 0;
        border-bottom-right-radius: 8px;
      }
      .icon {
        &::before {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
  .title {
    font-size: 16px;
  }
  .showTranslation {
    border-top: 2px dashed #999;
    overflow-x: scroll;
    padding-bottom: 10px;
  }
}
</style>
