<template>
  <el-card
    header="投放素材"
    class="material mt-20"
  >
    <div class="dp-flex">
      <div class="materialLeft flex-1">
        <div class="videos dp-flex">
          <video
            v-for="sample_video in sample_videos"
            :src="sample_video.url"
            :key="sample_video.id"
            :poster="sample_video.cover"
            controls
            class="mr-20 video"
          >
            您的浏览器不支持 video 标签。
          </video>
        </div>
        <!-- 广告语 -->
        <div class="slogan mr-20">
          <h4 class="title py-10 mt-20dp-flex justify-btw">
            <div>
              <span>广告语</span>
              <el-button
                type="text"
                @click="getChatGPT"
                class="ml-20"
                >{{ showChatGPT ? '编辑广告语' : 'chatGPT推荐' }}</el-button
              >
            </div>
            <el-button
              type="text"
              v-show="showChatGPT"
              @click="changeBatch"
              >换一批</el-button
            >
            <!-- <el-button
              type="text"
              v-show="!showChatGPT"
              @click="addSlogan"
              >新增广告语</el-button
            > -->
          </h4>

          <slogan
            :slogans="showSloganList"
            :edit="!showChatGPT"
            @addSelectSlogan="addSelectSlogan"
            @deleteSlogan="deleteSlogan"
            :chatGtpSloganTranslate="chatGtpSloganTranslate"
            :chatGtpSloganOptimization="chatGtpSloganOptimization"
            ref="slogan"
            @changeSlogan="onChangeSlogan"
            v-loading="loadingSlogan"
            element-loading-background="rgba(0, 0, 0, 0.6)"
          ></slogan>
          <div
            @click="addSlogan"
            class="addSlogan"
            v-show="!showChatGPT"
          >
            + 新增广告语
          </div>
        </div>
      </div>
      <!-- 预览 -->
      <div class="materialRight">
        <div class="selectType">
          <el-radio-group
            :value="use_diff_slogan"
            size="mini"
            @input="changeUse_diff_slogan"
          >
            <el-radio
              :value="use_diff_slogan"
              :label="true"
              >生成{{ this.sample_videos.length }}个创意</el-radio
            >
            <el-radio
              :value="use_diff_slogan"
              :label="false"
              >生成{{ this.sample_videos.length * slogans.length }}个创意</el-radio
            >
          </el-radio-group>
          <div class="tips mt-10">
            <span v-show="!use_diff_slogan">每个视频都使用不同的广告语生成创意</span>
            <span v-show="use_diff_slogan">每个视频使用相同的广告语生成创意</span>
          </div>
        </div>
        <preview :previewList="previewList"></preview>
      </div>
    </div>
  </el-card>
</template>
<script>
import Slogan from './Slogan';
import preview from './preview';

import { chatGtpSloganTranslate, chatGPTSloganRecommend, chatGtpSloganOptimization } from '@/api/fastDelivery';
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    sample_videos: {
      default: () => [],
      type: Array,
    },
    use_diff_slogan: {
      type: Boolean,
    },
  },
  components: { Slogan, preview },
  data() {
    return {
      showChatGPT: false, // 显示chatGpt推荐
      loadingSlogan: false, //广告语处理中
      slogans: [
        {
          content: '',
          title: '',
          description: '',
        },
      ], // 广告语
      chatGptSlogans: [],
      changeSlogan: false, // 修改过广告语
    };
  },
  computed: {
    previewList() {
      let list = [];
      if (this.use_diff_slogan) {
        let slogansLen = this.slogans.length;
        2;
        this.data.sample_videos.map((item, index) => {
          let i = index % slogansLen;
          list.push({
            content: this.slogans[i].content,
            title: this.slogans[i].title,
            description: this.slogans[i].description,
            url: item.url,
            cover: item.cover,
            message: this.slogans[i].message,
          });
        });
      } else {
        this.data.sample_videos.map((item) => {
          this.slogans.map((slogan) => {
            list.push({
              content: slogan.content,
              message: slogan.message,
              title: slogan.title,
              description: slogan.description,
              url: item.url,
              cover: item.cover,
            });
          });
        });
      }
      return list;
    },
    showSloganList() {
      if (this.showChatGPT) {
        return this.chatGptSlogans;
      }
      return this.slogans;
    },
  },
  methods: {
    changeUse_diff_slogan(val) {
      this.$emit('changeUse_diff_slogan', val);
    },
    // 添加chatGpt广告语
    addSelectSlogan(selectIndex = []) {
      const addList = this.chatGptSlogans.filter((item, index) => selectIndex.includes(index));
      if (this.changeSlogan) {
        this.slogans.push(...addList);
      } else {
        this.slogans = [...addList];
      }
      this.changeSlogan = true;
      this.showChatGPT = false;
    },
    // 新增广告语
    addSlogan() {
      this.slogans.push({
        content: '',
        title: '',
        description: '',
      });
    },
    // 删除广告语
    deleteSlogan(index) {
      if (this.slogans.length <= 1) {
        this.$message.error('至少保留1条广告语');
        return;
      }
      this.slogans.splice(index, 1);
    },
    // 翻译type:3 *
    chatGtpSloganTranslate(index) {
      return chatGtpSloganTranslate({
        type: 3,
        message: this.slogans[index].content,
        language: this.data.lang_code,
      }).then((res) => {
        if (res.code == 0) {
          this.$set(this.slogans[index], 'message', res.data);
        }
      });
    },
    // 更生动type:4.      *更精简 type:5
    chatGtpSloganOptimization(index, type) {
      return chatGtpSloganOptimization({
        type,
        message: this.slogans[index].message,
        language: this.data.lang_code,
      }).then((res) => {
        if (res.code == 0) {
          this.$set(this.slogans[index], 'message', res.data.message);
          this.$set(this.slogans[index], 'content', res.data.chineseMessage);
        }
      });
    },
    //
    getChatGPT() {
      if (this.showChatGPT) {
        this.showChatGPT = !this.showChatGPT;
        return;
      }
      this.changeBatch().then(() => {
        this.showChatGPT = !this.showChatGPT;
      });
    },
    // 换一批
    changeBatch() {
      let params = {
        saleId: this.data.sale_id,
        label: this.data.broadcast,
        count: 1,
        language: this.data.lang_code,
      };
      this.loadingSlogan = true;
      return chatGPTSloganRecommend(params)
        .then((res) => {
          this.chatGptSlogans = res.data.map((item) => ({ ...item, content: item.chineseMessage }));
        })
        .finally(() => {
          this.loadingSlogan = false;
        });
    },
    validate() {
      return this.$refs.slogan.validate();
    },
    //修改正文
    onChangeSlogan() {
      this.changeSlogan = true;
    },
  },
  watch: {
    use_diff_slogan(val) {
      console.log('use_diff_slogan', val);
    },
  },
};
</script>
<style lang="scss" scoped>
.material {
  .materialLeft {
    max-height: 620px;
    overflow: scroll;
  }
  .videos {
    width: 100%;
    overflow-x: auto;
  }
  .materialRight {
    width: 300px;
    margin-left: 50px;
  }

  .video {
    min-width: 150px;
    widows: 150px;
    height: 150px;
  }
  .tips {
    font-size: 12px;
    color: #999;
  }
  .addSlogan {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px dotted #e3e3e3;
    line-height: 40px;
    color: #409eff;
    text-align: center;
  }
}
</style>
